@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap");
:root {
  --color-black: hsl(0, 0%, 10%);
  --color-white: hsl(0, 0%, 100%);
  --color-darken: hsl(0, 0%, 20%);

  --color-main-web: #ca1515;
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  /* text-decoration: none; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* html {
  font-size: 100%;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  scroll-behavior: smooth;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
  color: var(--color-black);
  background: var(--color-white);
} */

a,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}

@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@-webkit-keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
.container-header-custom {
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.brand {
  font-family: "Rubik", sans-serif;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -1px;
  color: var(--color-main-web);
  text-transform: uppercase;
}

.header-custom {
  /* position: fixed; */
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  z-index: 100;
  background: var(--color-white);
  -webkit-box-shadow: var(--shadow-medium);
          box-shadow: var(--shadow-medium);
}
.header-custom .wrapper-header-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.header-item-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
          flex: 0 0 10%;
}
.header-item-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* padding-top: 15px; */
}
.header-item-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
          flex: 0 0 10%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.header-item-right .menu-icon {
  font-size: 1.35rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
  margin-left: 1rem;
  color: var(--color-black);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/* .header-custom .menu {
} */
.header-custom .menu > ul > li {
  display: inline-block;
  line-height: 3.125rem;
  margin-left: 1.5rem;
}
.header-custom .menu > ul > li.active {
	text-decoration: underline;
  color: #ca1515;
  text-decoration-color: #ca1515;
}
.header-custom .menu > ul > li.active a {
	/* text-decoration: underline; */
  color: #ca1515;
  /* text-decoration-color: #ca1515; */
}
.header-custom .menu > ul > li > a {
  position: relative;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: capitalize;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.header-custom .menu > ul > li .menu-subs {
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 1.50rem;
  padding: 0.75rem 0.75rem;
  /* padding: '4px 6px'; */
  z-index: 109;
  opacity: 0;
  visibility: hidden;
  border-radius: 0.25rem;
  border-top: 3px solid var(--color-main-web);
  background: var(--color-white);
  -webkit-box-shadow: var(--shadow-medium);
          box-shadow: var(--shadow-medium);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header-custom .menu > ul > li .menu-subs > ul > li {
  line-height: 1;
}
.header-custom .menu > ul > li .menu-subs > ul > li > a {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header-custom .menu > ul > li .menu-column-1 {
  min-width: 16rem;
  max-width: 20rem;
}
.header-custom .menu > ul > li .menu-subs.menu-mega {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.header-custom .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li {
  display: block;
  line-height: 1;
}
.header-custom .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 0.5rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: capitalize;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.header-custom .menu > ul > li .menu-subs.menu-column-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 68rem;
  width: 100%;
  padding: 1.25rem 1rem;
}
.header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  padding: 0 1rem;
}
.header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item .title {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.75rem 0;
  color: var(--color-main-web);
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item.text-center .title {
  text-align: center;
}
.header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item img {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 0.75rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.header-custom .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a:hover,
.header-custom .menu > ul > li .menu-subs > ul > li > a:hover {
  color: var(--color-main-web);
}
.header-item-right a:hover,
.header-custom .menu > ul > li:hover > a {
  color: var(--color-main-web);
}
.header-custom .menu-mobile-header,
.header-custom .menu-mobile-toggle {
  display: none;
}

@media screen and (min-width: 993px) {
  .header-custom .menu > ul > li.menu-item-has-children:hover .menu-subs {
    margin-top: 0.5rem;
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 992px) {
  .header-item-center {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .header-item-left, .header-item-right {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }

  .wrapper-header-custom {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
    padding: 0.5rem 0;
  }

  .header-custom .menu-mobile-toggle {
    position: relative;
    display: block;
    cursor: pointer;
    width: 1.75rem;
    height: 1rem;
    border: none;
    outline: none;
    margin-left: 1.25rem;
    margin-top: -0.25rem;
    background: none;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
  }
  .header-custom .menu-mobile-toggle span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    border: none;
    outline: none;
    opacity: 1;
    border-radius: 0.25rem;
    background: var(--color-black);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .header-custom .menu-mobile-toggle span:nth-child(1) {
    top: 0;
  }
  .header-custom .menu-mobile-toggle span:nth-child(2), .header-custom .menu-mobile-toggle span:nth-child(3) {
    top: 0.5rem;
  }
  .header-custom .menu-mobile-toggle span:nth-child(4) {
    top: 1rem;
  }
  .header-item-right {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header-custom .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    z-index: 111;
    overflow: hidden;
    background: var(--color-white);
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .header-custom .menu.active {
    -webkit-transform: translate(0%);
            transform: translate(0%);
  }
  .header-custom .menu > ul > li {
    display: block;
    line-height: 1;
    margin: 0;
  }
  .header-custom .menu > ul > li > a {
    display: block;
    line-height: 3.125rem;
    height: 3.125rem;
    padding: 0 3.125rem 0 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .header-custom .menu > ul > li > a i.fa {
    position: absolute;
    font-size: 1.25rem;
    line-height: 3.125rem;
    top: 0;
    right: 0;
    width: 3.125rem;
    height: 3.125rem;
    text-align: center;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .header-custom .menu .menu-mobile-header {
    position: relative;
    position: sticky;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    top: 0;
    height: 3.125rem;
    z-index: 110;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: var(--color-white);
  }
  .header-custom .menu .menu-mobile-header .menu-mobile-arrow {
    display: none;
    font-size: 1.25rem;
    line-height: 3.125rem;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--color-black);
  }
  .header-custom .menu .menu-mobile-header.active .menu-mobile-arrow {
    display: block;
  }
  .header-custom .menu .menu-mobile-header .menu-mobile-title {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: inherit;
    color: var(--color-black);
    text-transform: capitalize;
  }
  .header-custom .menu .menu-mobile-header .menu-mobile-close {
    font-size: 2.25rem;
    line-height: 3.125rem;
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    text-align: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--color-black);
  }
 
  .header-custom .menu .menu-section-custom {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .header-custom .menu > ul > li .menu-subs {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .header-custom .menu > ul > li .menu-subs.menu-mega {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .header-custom .menu > ul > li .menu-subs.active {
    display: block;
  }
  .header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    padding: 0rem;
  }
  .header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item img {
    margin-top: 0;
  }
  .header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item.text-center .title {
    margin-bottom: 1.25rem;
  }
  .header-custom .menu > ul > li .menu-subs.menu-column-4 > .list-item.text-center:last-child .title {
    margin-bottom: 0rem;
  }
  .header-custom .menu > ul > li .menu-subs > ul > li > a {
    display: block;
  }
  .header-custom .menu > ul > li .menu-subs.menu-mega > .list-item > ul {
    margin-bottom: 1rem;
  }
  .header-custom .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a {
    display: block;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
  }
  .overlay.active {
    opacity: 1;
    visibility: visible;
  }
}