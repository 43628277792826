/* body {
  font-family: "Rufner" !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 11 !important;
} */
@font-face {
  font-family: "Rufner";
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 11 !important;
  src: url(../src/assets/fonts/Rufner.otf) format("opentype");
}
/* h6 {
  margin: 0;
  color: #111111;
  font-weight: 400;
  font-family: "Rufner" !important;
} */
.ant-pagination-item-active {
  /* font-weight: 500; */
  background: #ca1515 !important;
  border-color: #ca1515 !important;
  /* color: rgb(255, 255, 255) !important; */
  z-index: 1 !important;
  color: white;
}
.ant-radio-button-wrapper:hover {
  color: #ca1515 !important;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  /* font-weight: 500; */
  background: #ca1515 !important;
  border-color: #ca1515 !important;
  color: rgb(255, 255, 255) !important;
  z-index: 1 !important;
  /* color: white; */
}
.ant-slider-track {
  /* font-weight: 500; */
  background: #ca1515 !important;
  border-color: #ca1515 !important;
  /* color: rgb(255, 255, 255) !important;
  z-index: 1 !important;
  color: white; */
}
.ant-slider-handle {
  /* font-weight: 500; */
  /* background: #ca1515 !important; */
  border-color: #ca1515 !important;
  /* color: rgb(255, 255, 255) !important;
  z-index: 1 !important;
  color: white; */
}
.ant-pagination-item-active a {
  color: rgb(255, 255, 255) !important;
}
.ant-pagination-item:hover {
  border-color: #ca1515 !important;
}
.ant-pagination-item a:hover {
  color: #ca1515 !important;
}
.filter-product a:hover {
  color: rgb(255, 255, 255) !important;
  background: #ca1515 !important;
  /* border-color: #ca1515 !important; */
}
.ant-checkbox-input:hover {
  /* color: rgb(255, 255, 255) !important; */
  border-color: #ca1515 !important;
}
.ant-checkbox-input {
  background: none;
  border-color: #ca1515 !important;
}
.ant-checkbox-inner {
  color: rgb(255, 255, 255) !important;
  border-color: #ca1515 !important;
  /* background: #ca1515 !important; */
}
.ant-checkbox-inner:hover {
  color: rgb(255, 255, 255) !important;
  border-color: #ca1515 !important;
  /* background: #ca1515 !important; */
}
/* .ant-checkbox-inner:after {
  color: rgb(255, 255, 255) !important;
  border-color: #ca1515 !important;
  background: #ca1515 !important;
} */
.ant-checkbox-checked .ant-checkbox-inner {
  color: rgb(255, 255, 255) !important;
  border-color: #ca1515 !important;
  background: #ca1515 !important;
}

.styled-table-variant {
  border-collapse: collapse;
  width: 100%;
  margin: 25px 0;
  font-size: 14px;
  /* font-size: 0.9em; */
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table-variant thead tr {
  background-color: #ca1515;
  color: #ffffff;
  text-align: left;
}

.styled-table-variant th,
td {
  padding: 12px 15px;
}

.styled-table-variant tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table-variant tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table-variant tbody tr:last-of-type {
  border-bottom: 2px solid #ca1515;
}

.styled-table-variant tbody tr.active-row {
  font-weight: bold;
  color: #ca1515;
}

.header__menu {
  padding: 30px 0 27px;
}

.header__menu ul li {
  list-style: none;
  display: inline-block;
  /* margin-right: 40px; */
  position: relative;
}

.header__menu ul li.active a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.header__menu ul li:hover a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.header__menu ul li:hover .dropdown {
  top: 27px;
  opacity: 1;
  /* background-color: #ca1515;
  color: #fff; */
  visibility: visible;
}

.header__menu ul li:last-child {
  margin-right: 0;
}

.header__menu ul li .dropdown {
  position: absolute;
  left: 0;
  top: 56px;
  width: 200px;
  background: #fff;
  /* color: #000; */
  /* background: #111111; */
  text-align: left;
  padding: 2px 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.header__menu ul li .dropdown li {
  display: block;
  margin-right: 0;
  border-bottom: 1px solid #c6c6c6;
}
.header__menu ul li .dropdown li:hover {
  display: block;
  margin-right: 0;
  background-color: #ca1515;
}

.header__menu ul li .dropdown li a {
  font-size: 14px;
  /* color: #ffffff; */
  color: #000;
  font-weight: 400;
  padding: 8px 20px;
  text-transform: capitalize;
}
.header__menu ul li .dropdown li a:hover {
  font-size: 14px;
  /* color: #ffffff; */
  color: #fff;
  font-weight: 400;
  padding: 8px 20px;
  text-transform: capitalize;
}

.header__menu ul li .dropdown li a:after {
  display: none;
}

.header__menu ul li a {
  font-size: 15px;
  text-transform: uppercase;
  color: #111111;
  font-weight: 500;
  display: block;
  padding: 2px 0;
  position: relative;
}

.header__menu ul li a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #ca1515;
  content: "";
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.ant-carousel .slick-dots li.slick-active {
  background-color: #ca1515;
  height: 5px;
  opacity: 1 !important;
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: #ca1515 !important;
  opacity: 1 !important;
}
.ant-carousel .slick-dots li {
  background-color: #ca1515;
  opacity: 0.8;
  height: 5px;
}
.ant-carousel .slick-dots {
  position: relative !important;
  margin-top: 50px !important;
}
.ant-carousel .slick-dots li button {
  background-color: #ca1515;
  opacity: 0.8;
  height: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__menu {
    display: none;
  }
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 767px) {
  .header__menu {
    display: none;
  }
}
